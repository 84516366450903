import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Table,
  Image,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import mainImg from "../../../src/images/postr_2025.jpg";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";

const Ltdf2025: React.FC = () => {
  const [cat, setCat] = useState([]);

  const maleParticipants = [
    {
      position: "TBA",
      raceNumber: "TBA",
      name: "TBA",
      finishTime: "TBA",
      distance: "TBA",
      badge: 50,
    },
  
  ];

  const femaleParticipants = [
    {
        position: "TBA",
        raceNumber: "TBA",
        name: "TBA",
        finishTime: "TBA",
        distance: "TBA",
        badge: 50,
      },
  ];

  const freeParticipants = [
    {
        position: "TBA",
        raceNumber: "TBA",
        name: "TBA",
        finishTime: "TBA",
        distance: "TBA",
        badge: 50,
      },
  ];

  const doublesParticipants = [
    {
        position: "TBA",
        raceNumber: "TBA",
        name: "TBA",
        finishTime: "TBA",
        distance: "TBA",
        badge: 50,
      },
  ];

  const handleSelect = (e) => {
    let sortedParticipants = [];
    if (e === "maleParticipants") {
      sortedParticipants = maleParticipants.sort((a, b) => a.badge - b.badge);
      setCat(sortedParticipants);
    }
    if (e === "femaleParticipants") {
      sortedParticipants = femaleParticipants.sort((a, b) => a.badge - b.badge);
      setCat(sortedParticipants);
    }
    if (e === "doublesParticipants") {
      sortedParticipants = doublesParticipants.sort(
        (a, b) => a.badge - b.badge
      );
      setCat(sortedParticipants);
    }
    if (e === "freeParticipants") {
      setCat(freeParticipants);
    }
  };

  return (
    <div className="brevet-container">
      <Container>
        <Row>
          <Col className="flyer-container">
            <Image src={mainImg} />
          </Col>
        </Row>
        <Row>
          <Col className="flyer-container">
            <p style={{ margin: 0 }}>
              <h1 className="custom-title">SAVE THE DATE</h1>
              <h1 className="custom-title">ABRIL 26 - MAYO 1 2025</h1>
            </p>
          </Col>

          <Row>
            <h1 className="custom-title">RUTA</h1>
            <iframe
              src="https://ridewithgps.com/embeds?type=route&id=49533809&metricUnits=true&sampleGraph=true"
              style={{ width: "900px", height: "500px", border: "none" }}
            ></iframe>

            
            <h1 className="custom-title">CDMX -  PUERTO ESCONDIDO</h1>

            
          </Row>
          <Row>
            <Col className="brevet-btn-container "></Col>
          </Row>
          <Row>
            <iframe
              style={{ width: "1200px", height: "800px", border: "none" }}
              src="https://www.youtube.com/embed/nEW1yGVhc4s?si=mXf9nJmrcuFTHk5H"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
            <Col className="brevet-btn-container ">
              <Button
                href="https://drive.google.com/uc?export=download&id=1PBHdIZHHJcNUX4Rh3xsqxaL36uWv3G8d"
                target="_blank"
                rel="noopener noreferrer"
                variant="dark"
              >
                DESCARGAR RUTA OFICIAL
              </Button>
            </Col>
          </Row>
          <Row>
            <h1 className="custom-title">INICIO DE CARRERA</h1>
            <h1 className="custom-title">
              {" "}
              <Countdown date={Date.parse("26 Apr 2025 01:00:00 GMT") + 1000} />
            </h1>
            <h1 className="custom-title"> INSCRIPCIONES ABIERTAS</h1>
            <Col className="brevet-btn-container ">
            <Button
                href="https://www.eventbrite.com.mx/e/le-tour-de-frankie-el-infierno-del-sur-2025-tickets-1127938677949?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl"
                target="_blank"
                rel="noopener noreferrer"
                variant="dark"
              
              >
                QUIERO INSCRIBIRME
              </Button>
              </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <h1 className="custom-title">LISTA DE CORREDORES</h1>
              <div className="btn-container">
                <DropdownButton
                  title="CATEGORY"
                  id="dropdown-menu-align-right"
                  variant="dark"
                  onSelect={handleSelect}
                >
                  <Dropdown.Item eventKey="femaleParticipants">
                    Femenino
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="freeParticipants">
                    Libre
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="maleParticipants">
                    Masculino
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="doublesParticipants">
                    Duplas
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="table-container">
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  variant="dark"
                  size="sm"
                >
                  <thead>
                    <tr>
                      <th>Cap Number</th>
                      <th>Position</th>
                      <th>Name</th>
                      <th>Distance</th>
                      <th>Finish Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cat.map((participant, index) => {
                      return (
                        <tr key={Math.random()}>
                          <td key={Math.random()}>{participant.raceNumber}</td>
                          <td key={Math.random()}>{participant.position}</td>
                          <td key={Math.random()}>{participant.name}</td>
                          <td key={Math.random()}>{participant.distance}</td>
                          <td key={Math.random()}>{participant.finishTime}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default Ltdf2025;



